/* Container styling */

.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.container:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Dropzone styling */
.dropzone {
  border: 2px dashed #007bff;
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
  background-color: #f1f9ff;
  border-radius: 12px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.dropzone:hover {
  background-color: #e0f0ff;
  border-color: #0056b3;
}

.dropzone p {
  margin: 0;
  font-size: 18px;
  color: #0056b3;
  font-weight: bold;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

tbody td {
  transition: background-color 0.3s ease;
}

tbody td:hover {
  background-color: #e9e9e9;
}

/* Loading spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.spinner-text {
  margin-left: 15px;
  font-size: 18px;
  color: #007bff;
  font-weight: bold;
}

/* Additional Styling */
h2 {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

p {
  color: #555;
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

button:active {
  background-color: #004080;
  transform: scale(0.98);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  table {
    font-size: 14px;
  }

  th,
  td {
    padding: 10px;
  }

  .dropzone {
    padding: 20px;
  }

  .spinner-text {
    font-size: 16px;
  }
}
